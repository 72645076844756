.buttonAsLink {
  font-weight: bold;
  background: none !important;
  border: none;
  /* padding: 0 !important; */
  /* color: #069; */
  /* text-decoration: underline; */
  cursor: pointer;
}
button.buttonAsLink:hover {
  color: #0abaee !important ;
}
button.buttonAsLink:focus {
  outline: none !important;
}
button.buttonAsLink:active {
  outline: none !important;
}

.pagination-container {
  margin: 5px 0px;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

// header {
//   &.site-header {
//     z-index: 998;
//   }
// }

.form-control[disabled] {
  padding-left: 5px;
  margin-top: 0.5rem;
}

.popover {
  max-width: 640px;
}

.sub-nav .nav-item {
  cursor: pointer;
}

.mandatory-question {
  color: red;
  margin-left: 5px;
}

.align-right {
  text-align: right;
}
.fullScreenModal {
  height: 100vh;
}

#org-chart-width-tester {
  position: absolute;
  white-space: nowrap;
  height: auto;
  width: auto;
  visibility: hidden;
}
.navbar-userBlock {
  min-width: 300px;
  display: flex;
  @media screen and (max-width: 1080px) {
    min-width: auto;
  }
}
.overlay {
  display: none;
  @media screen and (max-width: 1080px) {
    display: block;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 900;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    animation: fade 500ms;
  }
}

// to be removed after the changes have been reflected in css repo
@media screen and (min-width: 1200px) {
  .ubo-right-content {
    flex: 0 0 auto;
  }

  .ubo-org-chart {
    flex: 1 1 auto;
    padding-bottom: 30px;
    padding-top: 20px;
    height: 95%;
    overflow-y: auto;
  }
}

@media screen and (max-width: 1201px) {
  .ubo-org-chart {
    height: 500px;
  }
}
.button-grp {
  display: flex;
  justify-content: space-between;
}

.table-content {
  min-width: 70%;
  max-width: 70%;
}

.error-saving,
.color-red {
  color: red;
}

.download-link {
  color: #0abaee;
  cursor: pointer;
}

.centered-buttons {
  display: flex;
  justify-content: center;
}

.col-30-percent {
  width: 30%;
}

.table__header .cell__title.is-sortable {
  display: block;
}

.site-footer {
  transform: translateY(15%);
}

@media only screen and (min-width: 1080px) {
  .questionnaire--is-full {
    .questionnaire-sidebar {
      .side-nav {
        position: fixed;
        padding-top: 0.5rem;
      }
      .side-nav-items {
        padding-bottom: 0px;
      }
    }

    .side-nav-items::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    .side-nav-items::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}

.tabulator {
  .tabulator-header {
    .tabulator-col-title {
      width: calc(100% + 10px) !important;
      padding-right: 15px !important;
    }
    .tabulator-col-sorter {
      right: -10px !important;
    }
  }

  .tabulator-cell {
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
  }
}

.tabulator-tableHolder::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
}

.tabulator-tableHolder::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
