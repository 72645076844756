.questionnaire__link {
  cursor: pointer;
}
.cdd-auto-feature.editable {
  .form-control {
    background-color: white !important;
  }
}
.footer-buttons{
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}
