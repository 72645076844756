.statusInfoIcon,
.statusInfoDeas {
  display: inline-block !important;
  span {
    vertical-align: top;
  }
}

.section-status-info {
  margin: 20px;
  ul > li {
    margin: 0px 0px 10px 0px;
  }
}
