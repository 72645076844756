body.berlin-hyp-app {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app h1,
.berlin-hyp-app h2,
.berlin-hyp-app h3,
.berlin-hyp-app h4,
.berlin-hyp-app h5,
.berlin-hyp-app h6 {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important !important;
    font-weight: bold
}

.berlin-hyp-app label {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app .card__title--large {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important;
    font-weight: bold
}

.berlin-hyp-app .site-header {
    border-bottom: 3px red solid
}

.berlin-hyp-app .site-footer p {
    color: #7c909a
}

.berlin-hyp-app .site-footer .ft-polices-Link a {
    color: #7c909a
}

.berlin-hyp-app .user-group .user-group__company-name {
    color: #7c909a
}

.berlin-hyp-app .btn-outline-primary {
    color: red !important;
    border-color: red
}

.berlin-hyp-app .btn-outline-primary:hover,
.berlin-hyp-app .btn-outline-primary:focus,
.berlin-hyp-app .btn-outline-primary:active {
    background-color: red !important;
    color: #fff !important
}

.berlin-hyp-app .btn-outline-primary:not(:disabled):not(.disabled):active,
.berlin-hyp-app .btn-outline-primary:not(:disabled):not(.disabled).active,
.berlin-hyp-app .show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: red;
    border-color: red
}

.berlin-hyp-app .btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.berlin-hyp-app .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.berlin-hyp-app .show>.btn-outline-primary.dropdown-toggle:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(229, 0, 70, 0.25) !important;
    box-shadow: 0 0 0 0.2rem rgba(229, 0, 70, 0.25) !important
}

.berlin-hyp-app .btn-outline-primary:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(229, 0, 70, 0.25) !important;
    box-shadow: 0 0 0 0.2rem rgba(229, 0, 70, 0.25) !important
}

.berlin-hyp-app .btn-primary,
.berlin-hyp-app .introjs-nextbutton {
    background-color: red;
    border-color: red
}

.berlin-hyp-app .btn-primary:not(:disabled):not(.disabled):active:focus,
.berlin-hyp-app .introjs-nextbutton:not(:disabled):not(.disabled):active:focus,
.berlin-hyp-app .btn-primary:not(:disabled):not(.disabled).active:focus,
.berlin-hyp-app .introjs-nextbutton:not(:disabled):not(.disabled).active:focus,
.berlin-hyp-app .show>.btn-primary.dropdown-toggle:focus,
.berlin-hyp-app .show>.dropdown-toggle.introjs-nextbutton:focus {
    -webkit-box-shadow: 0 0 0 0.2rem rgba(229, 0, 70, 0.25) !important;
    box-shadow: 0 0 0 0.2rem rgba(229, 0, 70, 0.25) !important;
    background-color: red;
    border-color: red
}

.berlin-hyp-app .btn-danger {
    background-color: red;
    border-color: red
}

.berlin-hyp-app .btn-success {
    background-color: #2ba97a;
    border-color: #2ba97a
}

.berlin-hyp-app .btn-success:hover {
    background-color: #2ba97a;
    border-color: #2ba97a
}

.berlin-hyp-app .btn-success:not(:disabled):not(.disabled):active,
.berlin-hyp-app .btn-success:not(:disabled):not(.disabled).active,
.berlin-hyp-app .show>.btn-success.dropdown-toggle {
    background-color: #2ba97a;
    border-color: #2ba97a
}

.berlin-hyp-app .btn-primary,
.berlin-hyp-app .introjs-nextbutton {
    background-color: red;
    border-color: red
}

.berlin-hyp-app .btn-outline-secondary {
    color: #3c3c3c;
    border-color: #3c3c3c
}

.berlin-hyp-app .btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #3c3c3c;
    border-color: #3c3c3c
}

.berlin-hyp-app .filter.dropdown[data-dropdownstate="filled"] .btn-dark {
    border-color: #3c3c3c;
    background-color: #3c3c3c
}

.berlin-hyp-app .filter.dropdown[data-dropdownstate="filled"] .btn-dark .is-focused {
    -webkit-box-shadow: 0 0.2rem 0 0 rgba(68, 68, 68, 0.25) !important;
    box-shadow: 0 0.2rem 0 0 rgba(68, 68, 68, 0.25) !important
}

.berlin-hyp-app .btn-dark:not(:disabled):not(.disabled).active,
.berlin-hyp-app .btn-dark:not(:disabled):not(.disabled):active,
.berlin-hyp-app .show>.btn-dark.dropdown-toggle {
    background-color: #3c3c3c;
    border-color: #3c3c3c
}

.berlin-hyp-app .filters-container .is-focused {
    -webkit-box-shadow: 0 0.2rem 0 0 rgba(92, 91, 91, 0.25) !important;
    box-shadow: 0 0.2rem 0 0 rgba(92, 91, 91, 0.25) !important
}

.berlin-hyp-app .pagination .btn-outline-secondary:hover {
    color: #3c3c3c !important;
    background-color: #fff;
    border-color: #fbabab
}

.berlin-hyp-app .pagination .btn-outline-secondary:focus {
    border-color: #fef0f0;
    -webkit-box-shadow: none;
    box-shadow: none
}

.berlin-hyp-app .pagination .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.berlin-hyp-app .pagination .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.berlin-hyp-app .pagination .show>.btn-outline-secondary.dropdown-toggle:focus {
    color: #3c3c3c !important;
    background-color: #fff;
    border-color: #fbabab;
    -webkit-box-shadow: none;
    box-shadow: none
}

.berlin-hyp-app .pagination .btn-outline-secondary:not(:disabled):not(.disabled):active,
.berlin-hyp-app .pagination .btn-outline-secondary:not(:disabled):not(.disabled).active,
.berlin-hyp-app .pagination .show>.btn-outline-secondary.dropdown-toggle {
    color: #3c3c3c !important;
    background-color: #fff;
    border-color: #fbabab;
    -webkit-box-shadow: none;
    box-shadow: none
}

.berlin-hyp-app .pagination .pagination .btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.berlin-hyp-app .pagination .berlin-hyp-app .pagination .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.berlin-hyp-app .pagination .berlin-hyp-app .pagination .show>.btn-outline-secondary.dropdown-toggle:focus {
    color: #3c3c3c !important;
    background-color: #fff;
    border-color: #fbabab;
    -webkit-box-shadow: none;
    box-shadow: none
}

.berlin-hyp-app .popupAction .btn-default {
    border-color: red;
    color: red
}

.berlin-hyp-app .account-sign-in,
.berlin-hyp-app .account-sign-up,
.berlin-hyp-app .account-forgot-password .btn-primary:not(:disabled):not(.disabled):active:focus {
    -webkit-box-shadow: 0 0 0 0.2rem #fbabab !important;
    box-shadow: 0 0 0 0.2rem #fbabab !important
}

.berlin-hyp-app .btn.disabled,
.berlin-hyp-app .disabled.introjs-button,
.berlin-hyp-app .btn:disabled,
.berlin-hyp-app .introjs-button:disabled {
    opacity: 0.5
}

.berlin-hyp-app .btn-primary:not(:disabled):not(.disabled):active,
.berlin-hyp-app .introjs-nextbutton:not(:disabled):not(.disabled):active,
.berlin-hyp-app .btn-primary:not(:disabled):not(.disabled).active,
.berlin-hyp-app .introjs-nextbutton:not(:disabled):not(.disabled).active,
.berlin-hyp-app .show>.btn-primary.dropdown-toggle,
.berlin-hyp-app .show>.dropdown-toggle.introjs-nextbutton {
    background-color: red;
    border-color: red
}

.berlin-hyp-app .page-header__backlink a {
    color: red
}

.berlin-hyp-app .page-header {
    background-color: #3c3c3c
}

.berlin-hyp-app .sub-nav .nav-link.active {
    border-bottom: 3px solid red
}

.berlin-hyp-app .approval-accordian .approval-accordian-in .active .text-right .commonUl .btn,
.berlin-hyp-app .approval-accordian .approval-accordian-in .active .text-right .commonUl .introjs-butto {
    background-color: #2ba97a !important;
    border-color: #2ba97a !important
}

.berlin-hyp-app .kiid-approval-tab .kiid-app-tab-in li a.active::before {
    background-color: red !important
}

.berlin-hyp-app .submit-other-link {
    color: red !important
}

.berlin-hyp-app .account-sign-in .card__body .tnc-text a {
    color: red !important
}

.berlin-hyp-app .page-header {
    background-color: #f4f6f7
}

.berlin-hyp-app .document-preview__header .page-header .text-white {
    color: #000 !important
}

.berlin-hyp-app .page-header__title {
    color: #000;
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important;
    font-weight: bold
}

.berlin-hyp-app .sub-nav .nav-link {
    color: #000;
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important;
    font-weight: bold
}

.berlin-hyp-app .page-actions--dark {
    background-color: #dee3e6
}

.berlin-hyp-app .btn-dark {
    background-color: red;
    border-color: red
}

.berlin-hyp-app .page-action .filter .filter__clear.helperIcon {
    color: #7c909a
}

.berlin-hyp-app .card-onclick__target:before {
    border-top: 0.3em solid red !important
}

.berlin-hyp-app .card--accordion-list .card-onclick__target:before {
    border-top: 0.3em solid red
}

.berlin-hyp-app .action .icon {
    color: #cfcfcf
}

.berlin-hyp-app .action .icon:hover {
    color: red
}

.berlin-hyp-app .action .icon.text-primary {
    color: red !important
}

.berlin-hyp-app .rating--pending .rating__number {
    color: #7c909a !important
}

.berlin-hyp-app .rating--low {
    background-color: #2ba97a
}

.berlin-hyp-app .truncator__toggle {
    color: red
}

.berlin-hyp-app .deduction-input.is-edited .deduction-input__default-score {
    color: #d8d8d8
}

.berlin-hyp-app .custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: red !important
}

.berlin-hyp-app .custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: red !important
}

.berlin-hyp-app .form-score label {
    color: red !important
}

.berlin-hyp-app .form-section input.btn-outline-primary {
    border-color: red !important;
    color: red !important
}

.berlin-hyp-app .form-section input.btn-outline-primary:focus {
    -webkit-box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important;
    box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important
}

.berlin-hyp-app .form-section input.btn-outline-primary:active {
    -webkit-box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important;
    box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important
}

.berlin-hyp-app .form-section input.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    -webkit-box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important;
    box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important
}

.berlin-hyp-app .page-item.active .page-link {
    color: red
}

.berlin-hyp-app .page-link:hover {
    color: red
}

.berlin-hyp-app .page-header .details__list .detail a {
    color: #7c909a
}

.berlin-hyp-app .account-sign-in,
.berlin-hyp-app .account-sign-up,
.berlin-hyp-app .account-forgot-password,
.berlin-hyp-app .server-maintenance {
    background-color: #f4f6f7
}

.berlin-hyp-app .details__list .detail {
    color: #7c909a
}

.berlin-hyp-app .text--muted {
    color: #7c909a
}

.berlin-hyp-app .caret--before::before {
    border-top: 0.3rem solid red
}

.berlin-hyp-app .form .form-control.is-prefilled,
.berlin-hyp-app .form .form-control.is-prefilled .form-control,
.berlin-hyp-app .form .custom-select.is-prefilled,
.berlin-hyp-app .form .custom-select.is-prefilled .form-control,
.berlin-hyp-app .form .choices.is-prefilled,
.berlin-hyp-app .form .choices.is-prefilled .form-control,
.berlin-hyp-app .form .form-check-group.is-prefilled,
.berlin-hyp-app .form .form-check-group.is-prefilled .form-control {
    border-color: #2ba97a
}

.berlin-hyp-app .form .prefilled-feedback {
    color: #2ba97a
}

.berlin-hyp-app .progress-bar {
    background-color: red
}

.berlin-hyp-app .page-header__progress .progress {
    background-color: #3c3c3c
}

.berlin-hyp-app .custom-file-label {
    background-color: red;
    border-color: red
}

.berlin-hyp-app .main--dark-bg {
    background-color: #f4f6f7
}

.berlin-hyp-app .card__updated-by {
    color: #7c909a
}

.berlin-hyp-app .page-header__fund-selector .dropdown-toggle .dropdown-choices__selected {
    color: #303245
}

.berlin-hyp-app .modal__detail {
    color: #7c909a
}

.berlin-hyp-app .flag__code {
    color: #7c909a
}

.berlin-hyp-app .form-control--rounded-dark {
    background-color: #3c3c3c;
    border-color: #3c3c3c
}

.berlin-hyp-app .card__title--sub {
    color: #7c909a
}

.berlin-hyp-app .form-control--rounded {
    border-color: #cfcfcf;
    color: #3c3c3c
}

.berlin-hyp-app .sub-nav .nav-link:not(.active):hover {
    color: #7c909a
}

.berlin-hyp-app .text-primary {
    color: red !important;
    padding-left: 10px !important;
    padding-right: 10px !important
}

.berlin-hyp-app .icon.text-primary {
    color: red !important;
    padding-left: 0px !important;
    padding-right: 0px !important
}

.berlin-hyp-app .page-header__description {
    color: #7c909a
}

.berlin-hyp-app .article-helper[data-toggle="helper"] {
    -webkit-text-decoration-color: red;
    text-decoration-color: red
}

.berlin-hyp-app .wysiwyg a,
.berlin-hyp-app .redactor-styles a {
    color: red
}

.berlin-hyp-app .wysiwyg .is-checked::before,
.berlin-hyp-app .redactor-styles .is-checked::before {
    color: #2ba97a
}

.berlin-hyp-app .wysiwyg .is-unchecked:before,
.berlin-hyp-app .redactor-styles .is-unchecked:before {
    color: #f65656
}

.berlin-hyp-app .kiid-approval-tab .kiid-app-tab-in li a {
    color: #3c3c3c
}

.berlin-hyp-app .kiid-approval-tab .kiid-app-tab-in li.active a::before {
    background-color: red
}

.berlin-hyp-app .kiid-approval-tab .kiid-app-tab-in li a.active:hover {
    color: red
}

.berlin-hyp-app .kiid-approval-tab .kiid-app-tab-in li a.active {
    color: red
}

.berlin-hyp-app .kiid-approval-tab .kiid-app-tab-in li.active a {
    color: red
}

.berlin-hyp-app .approvalConfirmationPopup h4 svg {
    color: #2ba97a
}

.berlin-hyp-app .approval-accordian .approval-accordian-in .approvedBox .approvedBoxHeader .approvedBy {
    color: #2ba97a
}

.berlin-hyp-app .approval-accordian .approval-accordian-in .approvalGreyTxt {
    color: #7c909a
}

.berlin-hyp-app .approval-accordian .approval-accordian-in .approvedBox .approvalNote {
    color: #7c909a
}

.berlin-hyp-app .approval-accordian .approval-accordian-in .commentSuccessMsg {
    color: #2ba97a
}

.berlin-hyp-app .custom-checkbox .custom-control-input:checked~.custom-control-label::before {
    background-color: red
}

.berlin-hyp-app .dropdown-item.active,
.berlin-hyp-app .dropdown-item:active {
    color: red
}

.berlin-hyp-app .zeidler-verified-log-in,
.berlin-hyp-app .zeidler-verified-terms-and-privacy {
    background-color: #f4f6f7
}

.berlin-hyp-app .label--with-helper .label-helper:hover {
    color: #1bb3d3
}

.berlin-hyp-app .account-sign-in p {
    color: #3c3c3c
}

.berlin-hyp-app .account-sign-in .ft-polices-Link a,
.berlin-hyp-app .account-sign-up .ft-polices-Link a,
.berlin-hyp-app .account-forgot-password .ft-polices-Link a,
.berlin-hyp-app .server-maintenance .ft-polices-Link a {
    color: #7c909a
}

.berlin-hyp-app .flash-message--success .flash-message__item {
    background-color: #2ba97a
}

.berlin-hyp-app .flash-message--primary .flash-message__item {
    background-color: #207cc3
}

.berlin-hyp-app .flash-message--secondary .flash-message__item {
    background-color: #303245
}

.berlin-hyp-app .flash-message--success .flash-message__item {
    background-color: #2ba97a
}

.berlin-hyp-app .flash-message--info .flash-message__item {
    background-color: #f2b34c
}

.berlin-hyp-app .flash-message--warning .flash-message__item {
    background-color: #ef8386
}

.berlin-hyp-app .flash-message--danger .flash-message__item {
    background-color: red
}

.berlin-hyp-app .flash-message--light .flash-message__item {
    background-color: #dee2e6
}

.berlin-hyp-app .flash-message--dark .flash-message__item {
    background-color: #15151a
}

.berlin-hyp-app .flash-message--lighter .flash-message__item {
    background-color: #f6f6f6
}

.berlin-hyp-app .flash-message--base .flash-message__item {
    background-color: #adb5bd
}

.berlin-hyp-app .flash-message--notice .flash-message__item {
    background-color: #2ba97a
}

.berlin-hyp-app .form .form-control.is-open,
.berlin-hyp-app .form .form-control:focus {
    -webkit-box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important;
    box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important;
    color: #7c909a;
    background-color: #fff;
    border-color: red !important
}

.berlin-hyp-app .form-control:focus {
    border-color: red
}

.berlin-hyp-app .navbar-user-item .dropdown-toggle:hover {
    color: red !important
}

.berlin-hyp-app button.buttonAsLink:hover {
    color: red !important
}

.berlin-hyp-app .is-focused {
    -webkit-box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important;
    box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important
}

.berlin-hyp-app .is-focused .choices__inner,
.berlin-hyp-app .is-open .choices__inner {
    border-color: red;
    -webkit-box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important;
    box-shadow: 0 0.2rem 0 0 rgba(229, 0, 70, 0.25) !important
}

.berlin-hyp-app .custom-search .form-control:focus~.custom-search__icon svg {
    color: red
}

.berlin-hyp-app .custom-search .form-control~.custom-search__icon svg {
    color: #cfcfcf
}

.berlin-hyp-app .custom-search__icon {
    padding-top: 16px;
    color: #cfcfcf
}

.berlin-hyp-app .action.action--go:after {
    background-color: inherit !important
}

.berlin-hyp-app .navbar-user-item .dropdown-toggle {
    color: #cfcfcf
}

.berlin-hyp-app .navbar-user-item .dropdown-toggle:hover {
    color: red
}

.berlin-hyp-app .card-onclick:hover,
.berlin-hyp-app .card-onclickfocus {
    background-color: #f4f6f7
}

.berlin-hyp-app .card--accordion-list .justify-content-between .card-onclick__target:before {
    top: -0.166rem
}

.berlin-hyp-app .card--accordion-list.card--inner .card__accordion-header .card-onclick__target:before {
    top: -0.44rem
}

.berlin-hyp-app .card--accordion-list .card-onclick__target:before {
    top: -0.55rem
}

.berlin-hyp-app .users-count {
    background-color: red
}

.berlin-hyp-app .commonTab .nav-tabs>li>a.active,
.berlin-hyp-app .commonTab .nav-tabs>li>a.active:hover,
.berlin-hyp-app .commonTab .nav-tabs>li>a.active:focus {
    border-top: solid 4px red
}

.berlin-hyp-app .roles-Box-List-In .card-onclick__target:before {
    top: -0.099rem
}

.berlin-hyp-app .commonTab .nav-tabs>li.active>a,
.berlin-hyp-app .commonTab .nav-tabs>li.active>a:hover,
.berlin-hyp-app .commonTab .nav-tabs>li.active>a:focus {
    border-top: solid 4px red
}

.berlin-hyp-app .css-1pahdxg-control {
    border-color: red !important;
    -webkit-box-shadow: #ef8386 0px 0px 0px 0.1rem !important;
    box-shadow: #ef8386 0px 0px 0px 0.1rem !important
}

.berlin-hyp-app .css-1pahdxg-control:hover {
    border-color: red !important;
    -webkit-box-shadow: #ef8386 0px 0px 0px 0.1rem !important;
    box-shadow: #ef8386 0px 0px 0px 0.1rem !important
}

.berlin-hyp-app .css-1n7v3ny-option {
    background-color: #ffdede !important
}

.berlin-hyp-app .css-9gakcf-option {
    background-color: #ffdede !important;
    color: #000 !important
}

.berlin-hyp-app .css-yt9ioa-option:hover {
    background-color: #ffdede !important
}

.berlin-hyp-app .Toastify__toast--success {
    background-color: #95d4bc !important
}

.berlin-hyp-app .Toastify__toast--error {
    background-color: #f65656 !important
}

.berlin-hyp-app .cdd-auto-feature .saving-loading {
    color: #7c909a
}

.berlin-hyp-app .cdd-auto-feature .saving-loader-wrp {
    color: #7c909a
}

.berlin-hyp-app .cdd-auto-feature .locked-Error-Msg {
    background-color: #f2b34c
}

.berlin-hyp-app .cdd-auto-feature .locked-Error-Msg::before {
    background-color: #f2b34c
}

.berlin-hyp-app .cdd-auto-feature .validation-error-msg {
    background-color: #f65656
}

.berlin-hyp-app .cdd-auto-feature .validation-error-msg::before {
    background-color: #f65656
}

.berlin-hyp-app .see-error-Show .see-erros-msg {
    color: #f65656
}

.berlin-hyp-app .cdd-auto-feature .switch-input:checked+.switch-label::after {
    background-color: red
}

.berlin-hyp-app #cdd-groups-management .groups-management-form-content .groups-management-form-content-in .groups-management-form-content-left .assignSectionTab ul .nav-item .nav-link.active {
    border-left: solid 2px red
}

.berlin-hyp-app .custom-control-input:focus~.custom-control-label::before {
    -webkit-box-shadow: #ef8386 0px 0px 0px 0.1rem !important;
    box-shadow: #ef8386 0px 0px 0px 0.1rem !important
}

.berlin-hyp-app .form .form-control.is-prefilled .custom-control-input:checked~.custom-control-label::before,
.berlin-hyp-app .form .custom-select.is-prefilled .custom-control-input:checked~.custom-control-label::before,
.berlin-hyp-app .form .choices.is-prefilled .custom-control-input:checked~.custom-control-label::before,
.berlin-hyp-app .form .form-check-group.is-prefilled .custom-control-input:checked~.custom-control-label::before {
    background-color: red
}

.berlin-hyp-app .form .form-control.is-prefilled .custom-control-input:checked~.custom-control-label::before,
.berlin-hyp-app .form .custom-select.is-prefilled .custom-control-input:checked~.custom-control-label::before,
.berlin-hyp-app .form .choices.is-prefilled .custom-control-input:checked~.custom-control-label::before,
.berlin-hyp-app .form .form-check-group.is-prefilled .custom-control-input:checked~.custom-control-label::before {
    background-color: red
}

.berlin-hyp-app .custom-control-input:active~.custom-control-label::before {
    background-color: #fef0f0
}

.berlin-hyp-app .custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: red
}

.berlin-hyp-app .comment-tabs input:checked+label {
    border-bottom: 3px solid red
}

.berlin-hyp-app .btn-primary,
.berlin-hyp-app .introjs-nextbutton {
    background-color: red;
    border-color: red
}

.berlin-hyp-app .comment__private-note {
    color: red
}

.berlin-hyp-app .comment__private-note .text-primary {
    padding-left: 0px !important;
    padding-right: 0px !important
}

.berlin-hyp-app .comment__private-note .text-primary:hover {
    color: #7c909a !important
}

@media only screen and (min-width: 768px) {
    .berlin-hyp-app .navbar-brand .navbar-logo {
        width: 140px
    }
}

@media only screen and (max-width: 768px) {
    .berlin-hyp-app .sub-nav {
        border-bottom: solid 3px red
    }
}

.berlin-hyp-app .left-nav-step .success-voting {
    background-color: #ecf7f3 !important
}

.berlin-hyp-app .left-nav-step .success-line {
    border-left: solid 4px #2ba97a !important
}

.berlin-hyp-app .danger-line {
    border-left: solid 4px #f2b34c !important
}

.berlin-hyp-app .disabled-line {
    border-left: solid 4px #a2a2a2 !important
}

.berlin-hyp-app .left-nav-step .danger-voting {
    background-color: #fdf7ed
}

.berlin-hyp-app .left-nav-step .disable-voting {
    background-color: #f0f3f6
}

.berlin-hyp-app .file-upload-here .action .icon {
    color: #3c3c3c
}

.berlin-hyp-app .pdfpath {
    color: red !important
}

.berlin-hyp-app .custom-file-label {
    color: red !important
}

.berlin-hyp-app .company-ubo-button .border-none {
    color: red
}

.berlin-hyp-app .ubo-ui-sec .nav-link {
    color: red
}

.berlin-hyp-app .flash-message--info {
    background-color: #f2b34c
}

.berlin-hyp-app .left-nav-step .succes {
    background-color: #ecf7f3
}

.berlin-hyp-app .left-nav-step .success .nav-status-text {
    color: #2ba97a
}

.berlin-hyp-app .bt-switch-toggle input:checked+.slider {
    background-color: red
}

.berlin-hyp-app .action-group-in {
    background-color: #cfcfcf
}

.berlin-hyp-app .step-approval-number {
    background: #cfcfcf
}

.berlin-hyp-app .label-primary {
    background: #d8ecfc;
    color: #207cc3
}

.berlin-hyp-app .label-info {
    background: #faf1e3;
    color: #f2b34c
}

.berlin-hyp-app .step-approval-number {
    padding: 4px 11px 3px 11px
}

.berlin-hyp-app .approval-label-status {
    padding: 5px 10px 5px 10px
}

.berlin-hyp-app .cdd-auto-feature .hide-qu-icon svg {
    margin-top: 2px
}

.berlin-hyp-app .label-success {
    background: #ecf7f3;
    color: #2ba97a
}

.berlin-hyp-app .row-caret .dropdown-toggle:after {
    border-top: 0.3rem solid red
}

.berlin-hyp-app .page-action .btn-outline-secondary {
    color: red;
    border-color: red
}

.berlin-hyp-app .page-action .btn-outline-secondary:hover {
    background-color: red
}

.berlin-hyp-app .page-action .btn-outline-secondary:active {
    background: red;
    border-color: red
}

.berlin-hyp-app .page-action .btn-outline-secondary:focus,
.berlin-hyp-app .page-action .btn-outline-secondary.focus {
    -webkit-box-shadow: #ef8386 0px 0px 0px 0.1rem !important;
    box-shadow: #ef8386 0px 0px 0px 0.1rem !important
}

.berlin-hyp-app .languageBox .btn.active,
.berlin-hyp-app .languageBox .active.introjs-button {
    border-bottom: solid 2px red
}

.berlin-hyp-app svg.text-primary {
    padding: 0px !important
}

.berlin-hyp-app .help-text {
    color: red
}

.berlin-hyp-app .sub-nav.sub-nav--open .nav-link.active {
    color: red
}

.berlin-hyp-app .page-header__nav .sub-nav.sub-nav--open .nav-link.active {
    color: #020202 !important
}

@media only screen and (min-width: 1080px) {
    .berlin-hyp-app .table__cell--asset-manager {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 210px !important;
        flex: 0 0 210px !important;
        max-width: 210px !important
    }
}

.berlin-hyp-app .btn-link-underline {
    color: red !important
}

.berlin-hyp-app .custom-control-input:checked~.custom-control-label::before {
    border-color: red
}

.berlin-hyp-app .custom-control-label:hover::before {
    border-color: red;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.3);
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.3)
}

.berlin-hyp-app .form .form-check-group.is-prefilled .custom-control-label:hover::before {
    border-color: red;
    -webkit-box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.3);
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.3)
}

.berlin-hyp-app .table__header .cell__title.is-sortable.active:after {
    color: red
}

.berlin-hyp-app .btn-normal.active {
    color: red
}

.berlin-hyp-app .border-switch-toggle .bt-switch-toggle input:checked+.slider {
    border-color: red
}

.berlin-hyp-app .border-switch-toggle .bt-switch-toggle input:checked+.slider:before {
    background-color: #fff
}

.berlin-hyp-app .checkobox-v1 .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=UTF-8, %3csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.2803 0.96967C12.5732 1.26256 12.5732 1.73744 12.2803 2.03033L5.28033 9.03033C4.98744 9.32322 4.51256 9.32322 4.21967 9.03033L0.21967 5.03033C-0.0732233 4.73744 -0.0732233 4.26256 0.21967 3.96967C0.512563 3.67678 0.987437 3.67678 1.28033 3.96967L4.75 7.43934L11.2197 0.96967C11.5126 0.676777 11.9874 0.676777 12.2803 0.96967Z' fill='%23FFFFFF'/%3e%3c/svg%3e ") !important
}

.berlin-hyp-app .footer-wrap .ft-polices-Link {
    background-color: #fff
}

.berlin-hyp-app .footer-wrap .ft-polices-Link a {
    color: #918f8f
}

.berlin-hyp-app .copy-righ-text {
    background-color: #fff;
    border-top: solid 1px #e8e6e6;
    color: #918f8f
}

.berlin-hyp-app .custom_edit_text b {
    -webkit-text-stroke: 0.5px #30325e;
    -webkit-text-fill-color: #30325e;
    letter-spacing: 0.7px
}

.berlin-hyp-app .custom_edit_text strong {
    -webkit-text-stroke: 0.5px #30325e;
    -webkit-text-fill-color: #30325e;
    letter-spacing: 0.7px
}

.berlin-hyp-app .table__cell--asset-manager {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 150px !important;
    flex: 0 0 150px !important;
    max-width: 150px !important
}

.berlin-hyp-app .table__cell--distributor {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 170px !important;
    flex: 0 0 170px !important;
    max-width: 170px !important
}

.berlin-hyp-app .table__cell--account-groups {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 160px !important;
    flex: 0 0 160px !important;
    max-width: 160px !important
}

.berlin-hyp-app .table__cell--last-updated-date {
    -webkit-box-flex: 0 !important;
    -ms-flex: 0 0 145px !important;
    flex: 0 0 145px !important;
    max-width: 145px !important
}

.berlin-hyp-app .radio-left-text .custom-control-label {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative
}

.berlin-hyp-app .radio-left-text .custom-control-label::before {
    position: absolute;
    right: -10px;
    left: inherit
}

.berlin-hyp-app .radio-left-text .custom-control-label::after {
    position: relative;
    left: 10px
}

.berlin-hyp-app .approval-dropdown {
    z-index: 99 !important
}

.berlin-hyp-app .row-caret::before {
    z-index: 50 !important
}

.berlin-hyp-app .rating__number {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app .tooltip-inner {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app .dropdown-item {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app .modal__title {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app .modal-title {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app .h4,
.berlin-hyp-app .redactor-styles h4,
.berlin-hyp-app .wysiwyg h4 {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app select[multiple="multiple"],
.berlin-hyp-app textarea {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app h2.h2 {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app .card__title {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app h1 {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app h2 {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app h3 {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app h4 {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app h5 {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app h6 {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.berlin-hyp-app .page-header__title {
    font-family: "Arial", "Roboto", "Helvetica", "sans-serif" !important
}

.questionnaire-company-body {
    background-color: #f6f6f6;
    border: solid 1px #d9dae3;
    margin: 0px 30px 25px 30px;
    padding: 25px
}

.questionnaire-company-body .pb-10 {
    padding-bottom: 8px !important
}

.questionnaire-company-body .form-control {
    height: 38px
}

.font-20 {
    font-size: 20px
}

.font-18 {
    font-size: 18px
}

.company-field {
    margin-bottom: 30px
}

.font-weight-400 {
    font-weight: 400 !important
}

.opacity-0 {
    opacity: 0
}

.row-15 {
    margin-right: -15px !important;
    margin-left: -15px !important
}

.d-block {
    display: block
}

.pb-10 {
    padding-bottom: 10px !important
}

.pb-8 {
    padding-bottom: 8px !important
}

.questionnaire-company-body .form-group label {
    display: block;
    padding-bottom: 8px
}

.questionnaire-company-body .dropdown-select {
    height: 32px;
    border-radius: 4px;
    width: 100%;
    border: solid 1px #d9dae3;
    padding: 0 10px
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed
}

.disabled .form-control {
    background-color: #f2f2f2;
    cursor: not-allowed
}

.disabled .dropdown-select {
    background-color: #f2f2f2;
    cursor: not-allowed
}

.company-table-scroll {
    overflow-y: auto;
    max-height: 350px
}

.company-table-scroll table {
    margin-top: 0px
}

.company-table-scroll thead {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background: #f6f6f6
}

.company-table-scroll th {
    position: -webkit-sticky;
    position: sticky;
    top: 0
}

.company-table th {
    font-size: 14px;
    color: #868e96;
    padding-left: 15px;
    padding-right: 15px
}

.company-table tbody {
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    position: relative;
    z-index: 0
}

.company-table tbody tr {
    background-color: #fff;
    position: relative;
    cursor: pointer
}

.company-table tbody tr::after {
    position: absolute;
    left: 0;
    bottom: 3px;
    width: 100%;
    height: 3px;
    -webkit-box-shadow: 0 7px 3px 0 rgba(247, 10, 10, 0.2);
    box-shadow: 0 7px 3px 0 rgba(247, 10, 10, 0.2);
    content: ".";
    font-size: 0.1px;
    z-index: 1;
    opacity: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s
}

.company-table tbody tr:hover {
    -webkit-box-shadow: 0 0 4px 0 rgba(247, 10, 10, 0.2);
    box-shadow: 0 0 4px 0 rgba(247, 10, 10, 0.2)
}

.company-table tbody tr:hover::after {
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 1
}

.company-table tbody tr td {
    padding-left: 15px;
    padding-right: 15px;
    font-weight: bold;
    border-right: none !important;
    border-bottom: solid 1px red;
    border-top: none;
    font-size: 14px
}

.company-data-save {
    position: absolute;
    right: 0;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

.cursor-pointer {
    cursor: pointer
}

.company-search-modal .modal-dialog {
    max-width: 654px
}

.company-search-modal .modal__title {
    font-size: 18px
}

.company-search-modal .modal__header {
    padding: 0.6rem 1.5rem
}

.company-search-modal .modal__body {
    padding: 0.8rem 1.5rem
}

.company-search-modal .modal-content {
    border-radius: 0px
}

.bg-none {
    background-color: inherit !important
}

.questionnaire-company-body .form-control:disabled {
    background-color: #f0f0f0 !important
}

.new-cell-content {
    max-width: 700px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 80%;
    flex: 0 0 80%
}

.col-350-min {
    min-width: 350px
}

.col-370-min {
    min-width: 370px
}

.col-400-min {
    min-width: 400px
}

.col-450-min {
    min-width: 450px
}

.custom-title h1 {
    font-size: 24px !important;
    margin-bottom: 4px !important
}

.custom-title h2 {
    font-size: 20px !important;
    margin-bottom: 4px !important
}

.custom-title h3 {
    font-size: 18px !important;
    margin-bottom: 4px !important
}

.custom-title h4 {
    font-size: 16px !important;
    margin-bottom: 4px !important
}

.custom-title h5 {
    font-size: 14px !important;
    margin-bottom: 4px !important
}

.custom-title h6 {
    font-size: 12px !important;
    margin-bottom: 4px !important
}

.custom-title p {
    margin-bottom: 4px !important
}