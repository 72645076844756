.card--accordion-list {
  .card-onclick__target {
    display: flex;
    align-items: center;
  }
  .card-onclick__target:before {
    top: 0px !important;
  }
}

.tabulator {
  .tabulator-header {
    border-top: 0px;
  }
}

.accordion {
  border-top: 0px !important;

  .card--inner {
    border-radius: 0px !important;
  }
}

button:focus {
  outline: none !important;
}

.response__description-text {
  padding-bottom: 0px !important;
}

.review-questionnaire {
  .table-content {
    min-width: 80%;
    max-width: 80%;
  }
}
