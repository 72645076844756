.organizationChart {
  width: 100%;
  height: 100%;
  position: relative;
}

.organizationChart > svg:focus {
  outline: none;
}

.node:not([data-entity-type=add]) > .frame {
  fill: #ffffff;
}

.node[data-entity-type=root] > .frame {
  fill: #303246;
}

.node.dropdown {
  z-index: 1;
}

.node.selected > .frame {
  stroke: var(--primary-color);
  stroke-width: 2px;
}

.node[data-entity-type=add] > .frame {
  fill: var(--primary-color);
}

.node[data-entity-type=add] text {
  pointer-events: none;
}

.node[data-entity-type=add]:hover > .frame {
  stroke: var(--button-outline-color);
  stroke-width: 2;
  cursor: pointer;
}

.node text {
  dominant-baseline: central;
}

.node[data-entity-type=add] text {
  fill: #ffffff;
}

.node[data-entity-type=root] text {
  fill: #ffffff;
}

.links > line, .links > path {
  stroke: rgb(217, 218, 227);
  stroke-width: 2;
  fill: none;
}

.avatar[data-entity-type=company] > .bg, .avatar[data-entity-type=natural]:not(.ubo) > .bg {
  fill: rgb(246, 246, 246);
}

.avatar[data-entity-type=natural].ubo > .bg {
  fill: rgb(241, 227, 252);
}

.avatar > .icon {
  fill: #ADB5BD;
}

.avatar[data-entity-type=natural].ubo > .icon {
  fill: #C47DF8;
}

.trigger > path {
  transform-origin: 6px 5px;
}

.trigger .hitArea {
  opacity: 0;
}

.trigger .graphics {
  stroke: #D9DAE3;
  fill: none;
  stroke-linecap: round;
  stroke-width: 2;
}

.trigger:hover .graphics {
  stroke: #aeaeb4;
  stroke-width: 3;
  transition: all 400ms ease;
}

.zoomControls {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
}

.zoomControls button {
  width: 42px;
  height: 42px;
  background: #ffffff;
  border: 1px solid rgba(217, 218, 227, 1);
}

.zoomControls button svg path {
  stroke: rgba(89, 91, 107, 1);
}

.zoomControls button.in, .zoomControls button.out {
  font-size: 1.4rem;
}

.zoomControls button:not(.in):not(.out) {
  font-size: 0.6rem;
}

.zoomControls button:hover {
  background: #f3f3f3;
}

.zoomControls button:first-child {
  border-radius: 5px 5px 0 0;
}

.zoomControls button:not(:last-child) {
  border-bottom: none;
}

.zoomControls button:not(:first-child):not(:last-child) {
  height: 20px;
}

.zoomControls button:last-child {
  border-radius: 0 0 5px 5px;
}

.dropdown > path {
  fill: #ffffff;
}

.node.selected .dropdown > path {
  fill: #ffffff;
  stroke: var(--primary-color);
  stroke-width: 2;
}

.dropdown text.overall {
  font-weight: bold;
}

.dropdown text.relative {
  font-weight: normal;
}
