.table__cell--user-name,
.table__cell--email,
.table__cell--status,
.table__cell--roles,
.table__cell--account-groups,
.table__cell--actions {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;

  &:before {
    font-weight: 300;
    margin-bottom: 0.15rem;
  }
}

.table__cell--user-name:before {
  content: "Name";
}

.table__cell--email:before {
  content: "Email";
}

.table__cell--status:before {
  content: "Status";
}

.table__cell--roles:before {
  content: "Roles";
}

.table__cell--account-groups:before {
  content: "Account Groups";
}

.table__cell--actions:before {
  content: "Action";
}

.filters-container {
  .filter {
    max-width: 200px;
  }
}

@media only screen and (min-width: 1080px) {
  .table__cell--actions,
  .table__cell--status {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 160px;
    -ms-flex: 0 0 160px;
    flex: 0 0 160px !important;
    max-width: 160px !important;
  }

  .table__cell--roles,
  .table__cell--account-groups {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 180px;
    -ms-flex: 0 0 180px;
    flex: 0 0 180px !important;
    max-width: 180px !important;
  }
  .table__cell--email,
  .table__cell--name {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 200px;
    -ms-flex: 0 0 200px;
    flex: 0 0 200px !important;
    max-width: 200px !important;
  }
}
