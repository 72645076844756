.file-uploader__input {
  display: none;
}

.fixed-filename{
  width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
