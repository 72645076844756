.table__cell--asset-manager {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;

  &:before {
    font-weight: 300;
    margin-bottom: 0.15rem;
  }
}

.table__cell--asset-manager:before {
  content: "Name";
}

.assignments {
  .filters-container {
    .filter {
      max-width: 250px;
    }
  }
}

.cdd-groups-management .modal-dialog{
  max-width: 90% !important;
}

@media only screen and (min-width: 1080px) {
  .table__cell--asset-manager {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 160px;
    -ms-flex: 0 0 160px;
    flex: 0 0 160px !important;
    max-width: 160px !important;
  }
}

